import { useState,useEffect } from 'react'
// import classes from  './connectwallet.module.css'
import './connectwallet.css'

const Connectwallet = (props) => {

  
    return (
        <a href='https://mint.thechihuahuaclub.com/'>

   <button className='eightbit-btnc' >
    DAPP
   </button> 
        </a>

   )
{/* <button  id="wallet" className={classes.connect__wallet}  onClick={props.connect}>
{props.address? addr: "Connect Wallet"}
</button>  */}
//)
    

 
}

export default Connectwallet